import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  isOpen: state.comments.isOpen,
  comments: state.comments.comments,
})

const mapDispatch = (dispatch: any, props: { id: string }) => ({
  onClose: () => {
    dispatch(actions.comments.setOpen({ isOpen: false, articleId: props.id }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
