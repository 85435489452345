import React, { useContext } from "react"
import ReactMarkdown from "react-markdown"
import { ArticleQuote } from "../../../entities/ArticleEntity"
import { ThemeContext } from "../../Theme/Theme"

const components = {
  p: props => (
    <p className="md:max-w-3xl px-4 mx-auto py-2">{props.children}</p>
  ),
}

export const Quote: React.FC<ArticleQuote> = props => {
  const styles = useContext(ThemeContext)

  return (
    <div className="md:max-w-3xl px-4 mx-auto py-8 text-xl leading-tight md:text-2xl text-slate800">
      <div className={`pt-2 pb-1 pl-2 border-l-2 ${styles.border}`}>
        <ReactMarkdown components={{ ...components }}>
          {props.text}
        </ReactMarkdown>
        <ReactMarkdown components={{ ...components }}>
          {props.author}
        </ReactMarkdown>
      </div>
    </div>
  )
}
