import React, { useContext } from "react"
import { MarketingNewsletterType } from "../../../entities/MarketingEntity"
import { As } from "../../As/As"
import { ThemeContext } from "../../Theme/Theme"

export const Newsletter: React.FC<MarketingNewsletterType> = props => {
  const styles = useContext(ThemeContext)

  return (
    <section className="py-24 bg-slate900">
      <div className="container px-4 mx-auto">
        <div className="relative py-16 md:py-32 px-6 text-center bg-white overflow-hidden rounded-7xl">
          <div className="relative z-10 mx-auto md:max-w-3xl">
            <As
              component={props.title.component}
              className="mb-4 text-3xl md:text-4xl leading-tight font-bold tracking-tighter"
            >
              {props.title.value}
            </As>
            <p className="mb-8 text-lg md:text-xl text-slate-500 font-medium">
              {props.description}
            </p>
            <div className="mx-auto md:max-w-md text-left">
              <form
                className="flex flex-wrap mb-1"
                action={props.input.action}
                method={props.input.method}
              >
                <div className="w-full md:flex-1 mb-3 md:mb-0 md:mr-6">
                  <input
                    className={`w-full py-3 px-4 text-slate-500 leading-tight placeholder-slate-500 focus:outline-none focus:ring-2 ${styles.focus} border border-slate200 rounded-lg shadow-xsm`}
                    type="email"
                    placeholder={props.input.placeholder}
                  />
                </div>
                <div className="w-full md:w-auto">
                  <button
                    type="submit"
                    className={`inline-block py-3 px-5 w-full leading-5 text-white ${styles.bg} ${styles["hover:bg"]} ${styles.focus} font-medium text-center focus:ring-2  border border-transparent rounded-md shadow-sm`}
                  >
                    {props.button}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <img
            className="absolute top-0 left-0 w-28 md:w-auto"
            src="/wave2-yellow.svg"
            alt=""
          />
          <img
            className="absolute right-6 top-6 w-28 md:w-auto"
            src="/dots3-green.svg"
            alt=""
          />
          <img
            className="absolute right-0 bottom-0 w-28 md:w-auto"
            src="/wave3-red.svg"
            alt=""
          />
          <img
            className="absolute left-6 bottom-6 w-28 md:w-auto"
            src="/dots3-violet.svg"
            alt=""
          />
        </div>
      </div>
    </section>
  )
}
