import React from "react"

import ChatBubbleBottomCenterIcon from "@heroicons/react/24/solid/ChatBubbleBottomCenterIcon"
import { Bookmark } from "../../Bookmark/Bookmark"
import { Like } from "../../Like/Like"
import { CommentButton } from "../../CommentButton/CommentButton"

export const Interactions: React.FC<{ id: string }> = props => (
  <div className="px-4">
    <div className="max-w-3xl mt-12 mx-auto flex items-center text-slate-900 justify-between">
      <div className="flex items-center">
        <Like big id={props.id} />

        <div className="flex items-center">
          <CommentButton big id={props.id} />
        </div>
      </div>
      <div className="-mr-2">
        <Bookmark big id={props.id} />
      </div>
    </div>
  </div>
)
