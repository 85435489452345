import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useIntl } from "react-intl"
import { connector, ContainerProps } from "./containers/Comments.container"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { CommentTextarea } from "./components/CommentTextarea"
import { CommentEntity } from "../../entities/CommentEntity"
import dayjs from "dayjs"

dayjs.extend(require("dayjs/plugin/relativeTime"))

const Wrapper: React.FC<{
  isOpen: boolean
  onClose: () => void
  comments: CommentEntity[]
}> = props => {
  const intl = useIntl()

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => false}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-900 bg-opacity-20 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pt-10 md:pt-0">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full md:translate-x-full md:translate-y-0"
                enterTo="translate-x-0 translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="md:translate-x-0 translate-y-0"
                leaveTo="md:translate-x-full translate-y-full md:translate-y-0"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white rounded-md md:rounded-none py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-display font-medium text-slate-900">
                          <FormattedMessage id="comments/title" />{" "}
                          {props.comments.length > 0 ? `(${props.comments.length})` : ""}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-slate-400 hover:text-slate-500 focus:outline-none"
                            onClick={props.onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0">
                        <CommentTextarea />

                        <div className="pl-6 font-display mt-10">
                          <FormattedMessage id="comments/recents" />
                        </div>

                        <div className="w-full border-t border-slate-100 mt-4"></div>

                        <div className="px-4 sm:px-6">
                          {props.comments.map(comment => (
                            <div
                              className="border-b border-slate-100 py-10"
                              key={comment.id}
                            >
                              <div className="flex items-center">
                                <img
                                  src={comment.author.image}
                                  referrerPolicy="no-referrer"
                                  className="w-10 h-10 rounded-full"
                                />
                                <div className="font-display ml-2 text-slate-900 leading-none">
                                  <div>{comment.author.name}</div>
                                  <div className="font-regular text-sm text-slate-500">
                                    {/* @ts-ignore */}
                                    {dayjs(comment.publishedAt).fromNow()}
                                  </div>
                                </div>
                              </div>
                              <div className="text-sm mt-4 text-slate-900">
                                {comment.value}
                              </div>
                            </div>
                          ))}

                          {props.comments.length === 0 && (
                            <div className="mt-8 text-slate-700 italic w-full p-6 text-sm rounded bg-slate-50 border-slate-100 border">
                              <FormattedMessage id="comments/empty" />
                            </div>
                          )}
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const Comments = connector(Container)
