import React from "react"
import { Avatar } from "../../AuthenticatedUserAvatar/AuthenticatedUserAvatar"
import { Button } from "../../Button/Button"
import { useIntl } from "react-intl"
import {
  connector,
  ContainerProps,
} from "./containers/CommentTextarea.container"
import { UserEntity } from "../../../entities/UserEntity"
import { FormattedMessage } from "../../FormattedMessage/FormattedMessage"

const Wrapper: React.FC<{
  user: UserEntity | null
  value: string
  onChange: (value: string) => void
  onSubmit: () => void
  onLogin: () => void
}> = props => {
  const intl = useIntl()

  return (
    <div className="px-4 sm:px-6">
      {props.user && (
        <div className="w-full rounded shadow-md p-4">
          <div className="w-full flex items-center">
            <div>
              <Avatar />
            </div>
            <div className="font-display text-slate-900 ml-2">
              {props.user?.username}
            </div>
          </div>

          <textarea
            onChange={e => props.onChange(e.target.value)}
            className="w-full text-slate-900 text-sm py-2 resize-none h-28 outline-none placeholder-slate-400"
            value={props.value}
            placeholder={intl.formatMessage({
              id: "comments/input/placeholder",
            })}
          ></textarea>
          <div className="flex justify-end">
            <Button
              theme="outline"
              size="small"
              onClick={() => props.onChange("")}
            >
              <FormattedMessage id="comments/cancel" />
            </Button>
            <Button theme="solid" size="small" className="ml-2" onClick={() => props.onSubmit()}>
              <FormattedMessage id="comments/submit" />
            </Button>
          </div>
        </div>
      )}

      {!props.user && (
        <div className="w-full rounded shadow-md p-4 cursor-pointer" onClick={props.onLogin}>
          <div className="w-full flex items-center">
            <div className="text-slate-400 text-sm">
              <FormattedMessage id="comments/input/placeholder" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const CommentTextarea = connector(Container)
