import React from "react"
import HeartIcon from "@heroicons/react/24/outline/HeartIcon"
import HeartIconSolid from "@heroicons/react/24/solid/HeartIcon"
import { connector, ContainerProps } from "./containers/Like.container"
import clsx from "clsx"

export const Wrapper: React.FC<{
  liked: boolean
  big?: boolean
  number?: number,
  onClick: () => void
}> = props => {
  return (
    <>
      <div
        onClick={props.onClick}
        className={clsx(
          `hover:text-red-500 duration-300 transition-colors ease-in-out hover:bg-red-50 flex items-center rounded cursor-pointer`,
          props.liked && "text-red-500",
          !props.liked && "text-slate-900",
          props.big && "p-2"
        )}
      >
        {props.liked && <HeartIconSolid className={clsx("h-6 w-6")} />}
        {!props.liked && <HeartIcon className={clsx("h-6 w-6")} />}
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const Like = connector(Container)
