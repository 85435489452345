import { ArticleEntity } from "./ArticleEntity"

export enum MarketingTypes {
  DISCOVER = "marketing/discover",
  NEWSLETTER = "marketing/newsletter",
  FAQ = "marketing/faq",
  CATEGORY = "marketing/category",
  RELATED_ARTICLES = "marketing/related-articles",
  CONTACT_INLINE = "marketing/contact/inline",
}

export type MarketingCategoryType = {
  type: MarketingTypes.CATEGORY
  title: {
    value: string
    component: string
  }
  nbArticles?: number
  description: string
  label: {
    value: string
    component: string
  }
  more?: {
    label: string
    url: string
    rel: string
    target: string
  }
  articles: ArticleEntity[]
}

export type MarketingRelatedArticlesType = {
  type: MarketingTypes.RELATED_ARTICLES
  title: {
    value: string
    component: string
  }
  description: string
  label: {
    value: string
    component: string
  }
  articles: ArticleEntity[]
}

export type MarketingContactInline = {
  type: MarketingTypes.CONTACT_INLINE
  informations: {
    type: string
    label: string
    description: string
    information: string
  }[]
}

export type MarketingNewsletterType = {
  type: MarketingTypes.NEWSLETTER
  title: {
    component: string
    value: string
  }
  description: string
  input: {
    method: string
    placeholder: string
    action: string
  }
  button: string
}

type Faq = {
  question: string
  answer: string
  component: string
}

export type MarketingFaqType = {
  type: MarketingTypes.FAQ
  label: {
    component: string
    value: string
  }
  title: {
    component: string
    value: string
  }
  description: string
  items?: Array<Faq>
  cta: {
    visible: boolean
    title: {
      component: string
      value: string
    }
    description: string
    button: {
      label: string
      rel: string
      target: string
      url: string
    }
  }
}

export type MarketingDiscoverType = {
  type: MarketingTypes.DISCOVER

  image?: {
    src: string
    alt: string
  }
  label: {
    value: string
    component: string
  }
  title: {
    value: string
    component: string
  }
  description: string
  to: {
    label: string
    rel: "follow" | "nofollow" | "obfuscated"
    url: string
    target: string
  }
}

export type MarketingEntity =
  | MarketingNewsletterType
  | MarketingDiscoverType
  | MarketingFaqType
  | MarketingCategoryType
  | MarketingRelatedArticlesType
  | MarketingContactInline
