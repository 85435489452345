import React from "react"
import { Image } from "../../Image/Image"

export const Illustration = props => (
  <div className="px-4">
    <div className="mb-10 max-w-3xl mx-auto overflow-hidden rounded-lg">
      <Image className="w-full" src={props.src} alt={props.alt} />
    </div>
  </div>
)
