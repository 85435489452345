import React, { useContext } from "react"
import { MarketingFaqType } from "../../../entities/MarketingEntity"
import { As } from "../../As/As"
import { Badge } from "../../Badge/Badge"
import { Link } from "../../Link/Link"
import { ThemeContext } from "../../Theme/Theme"

export const Faq: React.FC<MarketingFaqType> = props => {
  const styles = useContext(ThemeContext)

  return (
    <section className="pt-24 bg-slate-50">
      <div className="container px-4 mx-auto">
        <div className="max-w-4xl mb-16">
          <Badge component={props.label.component}>{props.label.value}</Badge>
          <As
            component={props.title.component}
            className="mb-4 text-4xl md:text-5xl leading-tight text-slate-900 font-bold tracking-tighter"
          >
            {props.title.value}
          </As>
          <p className="text-lg md:text-xl text-slate-500 font-medium">
            {props.description}
          </p>
        </div>
        <div className="flex flex-wrap pb-16 -mx-4">
          {props.items?.map(item => (
            <div
              className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8"
              key={item.question}
            >
              <div className="md:max-w-xs">
                <div
                  className={`inline-flex mb-6 items-center justify-center w-12 h-12 rounded-full bg-${styles.theme}-500`}
                >
                  <img src="/shield-icon.svg" alt="" />
                </div>
                <As
                  component={item.component}
                  className="mb-6 text-xl font-bold text-slate-900"
                >
                  {item.question}
                </As>
                <p className="font-medium text-slate-500">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>

        {props.cta && props.cta.visible && (
          <div className="relative -mb-40 py-16 px-4 md:px-8 lg:px-16 bg-slate-900 rounded-md overflow-hidden">
            <div className="relative max-w-max mx-auto text-center">
              <As
                component={props.cta.title.component}
                className="mb-2 text-2xl md:text-5xl leading-tight font-bold text-white tracking-tighter"
              >
                {props.cta.title.value}
              </As>
              <p className="mb-6 text-base md:text-xl text-slate-400">
                {props.cta.description}
              </p>
              <Link
                className={`inline-flex items-center justify-center px-7 py-3 h-14 w-full md:w-auto mb-2 md:mb-0 md:mr-4 text-lg leading-7 text-white ${styles.bg} ${styles["hover:bg"]} ${styles.focus} font-medium border border-transparent rounded-md shadow-sm`}
                url={props.cta.button.url}
              >
                {props.cta.button.label}
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="h-64 bg-white"></div>
    </section>
  )
}
