import React, { useContext } from "react"
import { Image } from "../../Image/Image"
import { Link } from "../../Link/Link"
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon"
import { As } from "../../As/As"
import { MarketingDiscoverType } from "../../../entities/MarketingEntity"
import { ThemeContext } from "../../Theme/Theme"
import { Badge } from "../../Badge/Badge"
import ReactMarkdown from "react-markdown"

export const Discover: React.FC<MarketingDiscoverType> = props => {
  const styles = useContext(ThemeContext)

  return (
    <div className="w-full bg-slate50 overflow-hidden">
      <div className="container mx-auto">
        <div className="relative px-4 pt-10 pb-10 lg:pb-20 sm:pt-16 lg:pt-20">
          <div className="relative flex flex-wrap mx-auto items-center">
            <div className="w-full md:w-1/2">
              <div className=" md:aspect-h-1 md:aspect-w-1">
                <Image
                  className="rounded-lg object-cover w-full h-48 md:h-full"
                  src={props.image?.src}
                  alt={props.image?.alt}
                />
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-10 md:mt-0 md:flex md:items-center">
              <div className="md:pl-8 lg:pl-20">
                <Badge component={props.label.component}>
                  {props.label.value}
                </Badge>

                <As
                  component={props.title.component}
                  className="mt-2 text-3xl font-bold text-slate-800 md:text-4xl"
                >
                  {props.title.value}
                </As>

                <ReactMarkdown className="mt-8 text-lg text-slate-700">
                  {props.description}
                </ReactMarkdown>

                <div
                  className={`flex items-center mt-8 text-lg font-medium text-${styles.theme}-600 group`}
                >
                  <Link rel={props.to.rel} url={props.to.url}>
                    {props.to.label}
                  </Link>
                  <ArrowRightIcon className="w-5 ml-3 transform ease-in-out duration-300 group-hover:translate-x-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
