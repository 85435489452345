import React from "react"
import clsx from "clsx"
import { connector, ContainerProps } from "./containers/CommentButton.container"
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline"

export const Wrapper: React.FC<{
  onClick: () => void
  big?: boolean
  number?: number
}> = props => {
  return (
    <>
      <div
        onClick={props.onClick}
        className={clsx(
          `text-slate-900 duration-300 transition-colors ease-in-out hover:bg-slate-50 flex items-center rounded cursor-pointer`,
          props.big && "p-2"
        )}
      >
        <ChatBubbleBottomCenterIcon className="h-6 w-6" />
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const CommentButton = connector(Container)
