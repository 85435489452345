import React, { useEffect, useState } from "react"
import { Link } from "../Link/Link"
import ShareIcon from "@heroicons/react/20/solid/ShareIcon"
import ArrowLeftIcon from "@heroicons/react/20/solid/ArrowLeftIcon"
import { Bookmark } from "../Bookmark/Bookmark"
import { navigate, useLocation } from "@reach/router"
import { getHomePageUrl } from "../../utils/i18nUrls"
import { useIntl } from "react-intl"

export const MobileHeader = props => {
  const [url, setUrl] = useState("")
  const [shareable, setShareable] = useState(false)
  const location = useLocation()
  const intl = useIntl()

  useEffect(() => {
    setUrl(window.location.href)
    setShareable(!!navigator.share)
  }, [])

  return (
    <section className="bg-white lg:hidden justify-between flex items-center pr-4 w-full border-b border-slate-200 py-1">
      {location.state?.userIsNavigatingFromTheWebsite && (
        <button
          className={`h-12 w-12 rounded transition-colors ease-in-out duration-300 hover:bg-slate-100 flex text-slate-900 items-center justify-center`}
          onClick={() => navigate(-1)}
        >
          <ArrowLeftIcon className="w-6 h-6" />
        </button>
      )}

      {!location.state?.userIsNavigatingFromTheWebsite && (
        <Link
          className={`h-12 w-12 rounded transition-colors ease-in-out duration-300 hover:bg-slate-100 flex text-slate-900 items-center justify-center`}
          url={getHomePageUrl(intl.locale)}
        >
          <ArrowLeftIcon className="w-6 h-6" />
        </Link>
      )}

      <div className="flex items-center">
        {shareable && (
          <div
            onClick={async () => {
              try {
                await navigator.share({
                  url,
                  title: props.title,
                  text: props.description,
                })
              } catch (e) {}
            }}
            className={`h-12 w-12 rounded transition-colors ease-in-out duration-300 hover:bg-slate-100 flex text-slate-900 items-center justify-center`}
          >
            <ShareIcon className="h-6 w-6" />
          </div>
        )}

        <Bookmark big id={props.id} />
      </div>
    </section>
  )
}
