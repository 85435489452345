import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: { id: string }) => ({})

const mapDispatch = (dispatch: any, props: { id: string }) => ({
  onClick: () => {
    dispatch(actions.comments.setOpen({ isOpen: true, articleId: props.id }))
    dispatch(actions.comments.$fetchAll(props.id))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
