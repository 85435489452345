import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"
import { openLoginModal } from "../../../../utils/authModal"

const mapState = (state: RootState) => ({
  user: state.auth.user,
  value: state.comments.value,
})

const mapDispatch = (dispatch: any) => ({
  onChange: (value: string) => {
    dispatch(actions.comments.update({ value }))
  },
  onSubmit: () => {
    dispatch(actions.comments.$add())
  },
  onLogin: () => {
    openLoginModal()
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
