import { useState, useEffect } from "react"

export function useShare() {
  const [url, setUrl] = useState("")
  const [shareable, setShareable] = useState(false)

  useEffect(() => {
    setUrl(window.location.href)
    setShareable(!!navigator.share)
  }, [])

  return {
    url,
    shareable,
  }
}
